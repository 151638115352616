import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/loginGuide',
    component: HomeView,
    children: [
      //登录引导
      {
        path: '/loginGuide',
        name: 'LoginGuide',
        component: () => import('../views/loginGuide.vue'),
      },
      //登录
      {
        path: '/login/:terminal',
        name: 'Login',
        component: () => import('../views/login.vue'),
      },
      //首页
      {
        path: '/index',
        name: 'Index',
        component: () => import('../views/index.vue'),
      },
      //批次
      {
        path: '/batch',
        name: 'Batch',
        component: () => import('../views/batch.vue'),
      },
      //my
      {
        path: '/my',
        name: 'My',
        component: () => import('../views/my.vue'),
      },
      //基地详情
      {
        path: '/batchInfo',
        name: 'BatchInfo',
        component: () => import('../views/batchInfo.vue'),
      },
      //新闻详情
      {
        path: '/newsInfo/:id',
        name: 'NewsInfo',
        component: () => import('../views/newsInfo.vue'),
      },
      //每日打卡/查看信息
      {
        path: '/baseEverycard',
        name: 'BaseEverycard',
        component: () => import('../views/baseEverycard.vue'),
      },
      //批次信息详情
      {
        path: '/batchDetails',
        name: 'BatchDetails',
        component: () => import('../views/batchDetails.vue'),
      },
      //反馈
      {
        path: '/feedback',
        name: 'Feedback',
        component: () => import('../views/feedback.vue'),
      },
      //投诉
      {
        path: '/complaints',
        name: 'Complaints',
        component: () => import('../views/feedback.vue'),
      },
      //修改个人信息
      {
        path: '/editInfo',
        name: 'EditInfo',
        component: () => import('../views/editInfo.vue'),
      },
      //修改页
      {
        path: '/edit',
        name: 'Edit',
        component: () => import('../views/edit.vue'),
      },
      //打卡页
      {
        path: '/baseDetail',
        name: 'BaseDetail',
        component: () => import('../views/baseDetail.vue'),
      },
      //每日打卡信息
      {
        path: '/cardInfo',
        name: 'CardInfo',
        component: () => import('../views/cardInfo.vue'),
      },
      //每日打卡信息
      {
        path: '/detailInfo',
        name: 'DetailInfo',
        component: () => import('../views/detailInfo.vue'),
      },
      //我管理的批次
      {
        path: '/myBatch',
        name: 'MyBatch',
        component: () => import('../views/myBatch.vue'),
      },
      //评价
      {
        path: '/evaluate',
        name: 'Evaluate',
        component: () => import('../views/evaluate.vue'),
      },
      //疫情
      {
        path: '/epidemicInfo',
        name: 'EpidemicInfo',
        component: () => import('../views/epidemicInfo.vue'),
      },
      //我的评价
      {
        path: '/evaluateInfo',
        name: 'EvaluateInfo',
        component: () => import('../views/evaluateInfo.vue'),
      },
      //每日疫情打卡
      {
        path: '/everydayupload',
        name: 'Everydayupload',
        component: () => import('../views/everydayupload.vue'),
      },
      //预约
      {
        path: '/reserve',
        name: 'Reserve',
        component: () => import('../views/reserve.vue'),
      },
      //iframe
      {
        path: '/iframe',
        name: 'Iframe',
        component: () => import('../views/iframe.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
